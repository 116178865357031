import {ReactNode, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';

import {Hooks} from 'utils';
import {Button} from '../Button';
import {ModalAction} from "../Modal";

///
/// Do not add any more to gift modal... this is a crap component. Use the generic Modal instead
///


export type GiftModelType = 'completed'
    | 'delete'
    | 'drafted'
    | 'reviewing'
    | 'subscribed-reviewing'
    | 'not-subscribed-drafted'
    | 'subscription-required'
    | 'hide-gift'
    | 'wave-list-action-'
    | 'wave-list-action-reject'
    | 'wave-list-action-accept'
    | 'nudge'
    | 'wave-list-action-shortlist'
    | 'wave-list-action-complete'
    | 'chat-message-leakage'
    | 'password-changed'
    | 'not-chatted'
    | 'creator-recently-booked-accept'
    ;

interface Props {
    type?: GiftModelType;
    show?: boolean;
    onClose(): void;
    primaryAction?: ModalAction;
    secondaryAction?: ModalAction;
    data?: any;
}

const Image = styled.img`
    width: 220px;
    height: 220px;

    @media (max-height:711px) and (min-height:0px) {
        display: none;
    }
`;

const Title = styled.span`
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    margin-top: -20px;
    margin-bottom: 20px;
    letter-spacing: -1.25px;
    color: var(--grey-9);
    text-align: center;

    @media (max-height:711px) and (min-height:0px) {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
    }

`;

const Desc = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: var(--grey-6);
    white-space: break-spaces;

    @media (max-height:711px) and (min-height:0px) {
        font-size: 14px;
        line-height: 20px;
    }

`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-height:711px) and (min-height:0px) {
        padding-top: 24px;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;

    @media (max-height:711px) and (min-height:0px) {
        margin-top: 24px;
    }

`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }
`;

const CopyInput = styled.div`
    display: flex;
    position: relative;
    border: solid 1px var(--blue);
    border-radius: 6px;
    padding: 3px 4px;
    align-items: center;
    margin-top: 12px;
    color: var(--blue) !important;
    font-weight: 500;

    div {
        color: var(--blue) !important;
    }

    input {
        border: none;
        width: 100%;
    }

    button {
        position: absolute;
        right: 4px;
        background: none;
        border: solid 1px var(--blue);
        padding: 0px 2px;
        border-radius: 6px;
        cursor: pointer;
        background: white;
        z-index: 5;
    }
`

export function GiftModal({
    type,
    show,
    onClose,
    primaryAction,
    secondaryAction,
    data
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);
    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    let btnText = '';
    const renderContent = () => {
        let image: string;
        let title: string;
        let caption: string;

        switch (type) {
            case 'completed':
                image = 'gift';
                title = 'Are you sure you want to close this gift?';
                caption = 'Closing the gift will stop all creators from applying for your gift and ask them to review your collab if they were selected';
                break;

            case 'delete':
                image = 'lightning';
                title = 'Are you sure?';
                caption = 'Are you sure about you want to delete\nthis gift permanently?';
                break;

            case 'nudge':
                image = 'gift';
                title = 'Nudge this creator?';
                caption = `The nudge button sends an alert to the creator that you are waiting to hear from them and havent had a response to your messages. It’s a ‘last chance!’ before you cancel the collaboration or leave review. They will receive a push alert on the app, and an email`
                break;
                
            case 'drafted':
                image = 'box';
                title = 'Drafted';
                caption = 'Your gift has been successfully added\non draft gifts.';
                break;

            case 'reviewing':
                image = 'sandwatch';
                title = 'Reviewing...';
                caption = 'Your gift has been successfully submitted.\nOur team is reviewing your gift.\nWe will update you soon.'
                break;

            case 'subscribed-reviewing':
                image = 'gift';
                title = 'Welcome to #gifted!';
                caption = 'Your gift has been successfully submitted.\nOur team is reviewing your gift.\nWe will update you soon.'
                break;

            case 'not-subscribed-drafted':
                image = 'box';
                title = 'Subscribe later';
                caption = 'Your gift has been successfully added\non draft gifts.';
                break;

            case 'subscription-required':
                image = 'gift'
                title = 'Subscribe to #gifted';
                caption = 'You need to subscribe to submit this gift.';
                break;

            case 'password-changed':
                image = 'lock';
                title = 'Password Changed!';
                caption = 'Congratulation! Your password has\nbeen successfully changed.';
                break;

            case 'wave-list-action-':
                image = 'gift'
                title = '';
                caption = '';
                break;

            case 'wave-list-action-reject':
                image = 'gift'
                title = 'Decline this creator?';
                caption = 'Declining this creator will deselect them from gifting. This creator will not be notified unless they have already been selected. Declined creators will not receive notifications unless you message them.';
                btnText = 'Decline this creator';
                break;

            case 'wave-list-action-shortlist':
                image = 'gift'
                title = 'Shortlist this creator?';
                caption = 'Shortlisting this creator will move this creator to your shortlist. Your shortlist contains creators you\'re thinking of working with. Shortlisted creators will not be notified unless you message them.';
                btnText = 'Shortlist this creator';
                break;

            case 'wave-list-action-accept':
                image = 'gift'
                title = 'Accept this creator?';
                caption = 'Accepting this creator will select this creator for gifting. The creator will receive a message that you will gift them, collect their shipping information, and if applicable, have them select a shopify product variant.';
                btnText = 'Accept this creator';
                break;

            case 'wave-list-action-complete':
                image = 'gift'
                title = 'Complete this creator?';
                caption = 'Completing this creator will end this creators campaign and prompt them for a review. Only complete a creator once you are satisfied with their work.';
                btnText = 'Complete this creator';
                break;

            case 'chat-message-leakage':
                image = 'lock'
                title = 'You have not accepted the creator for gifting.';
                caption = 'You can only share personal information, such as shipping addresses, phone numbers, and email addresses after accepting the creator for gifting.\n\nTo accept a creator for gifting click the accept button.';
                btnText = 'Dismiss';
                break;

            case 'hide-gift':
                image = 'lock';
                title = primaryAction?.name === 'Unhide Gift' ? 'Do you want new creators to apply?' : 'Do you want to stop new creators to applying?';
                caption = primaryAction?.name === 'Unhide Gift' ? 'Your gift will appear again in the #Gifted mobile app for creators.' : 'You will still be able to interact with creators that have already applied.';
                break;

            case 'creator-recently-booked-accept':
                image = 'lightning';
                title = 'You have accepted this creator for another gift';
                caption = 'This creator was accepted on another of your gifts in the last 30 days.\nAre you sure you want to accept them for gifting?';
                btnText = 'Accept this creator';
                break;

            case 'not-chatted':
                image = 'lightning';
                title = 'You have not chatted with this creator';
                caption = `Are you sure you’re ready to confirm this collab when you haven't chatted to the creator yet? \n\n We recommend you chat first to agree what they can offer in return for the gift.`;
                btnText = 'Accept this creator';
                break;

            default: return null;
        }

        return (
            <Body>
                <Image
                    src={require(`../../../images/${image}.png`)}
                    alt={`${type} gift`}
                />

                <Title>
                    {title}
                </Title>

                <Desc>
                    {caption}
                </Desc>
            </Body>
        );
    }



    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'gift-modal-component'}
                >
                    <Content
                        {...contentAnimation}
                        ref={contentRef}
                    >
                        {renderContent()}

                        <Actions>
                            {primaryAction && (
                                <Button onClick={primaryAction.action}>
                                    {btnText ? btnText : primaryAction.name}
                                </Button>
                            )}

                            {secondaryAction && (
                                <Button
                                    theme={'outline'}
                                    onClick={secondaryAction.action}
                                >
                                    {secondaryAction.name}
                                </Button>
                            )}
                        </Actions>
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
}
