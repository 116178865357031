import axios from '../api/axios';

export type ClientSettings = {
    stripe: {
        key: string,
    },
    shopify: {
        storeUrl: string,
    },
    pusher: {
        app_id: string,
        key: string,
        cluster: string
    },
    logrocket: {
        web: string|null;
    }
    files: {
        max_upload_size: number;
        temp_upload_url: boolean;
    },
    allowedMimeTypes: {
        documents: Array<string>;
        images: Array<string>;
        videos: Array<string>;
    };
    gifts: {
        max_gift_media: number;
        min_gift_value: number;
        paid_types: GiftType[]
    },
    brands: {
        min_tags: number;
        max_tags: number;
    },
    features: {
        subscriptions: boolean;
        'subscriptions.trial_days': number;
        shopify: boolean;
        yotpo: boolean;
        'completed.content': boolean,
        logrocket: boolean,
        wave_filters: boolean,
        waves_filters: boolean,
        feedback_form_url: string,
        brandSignup: boolean,
        'completed.content.stats': boolean,
        'influencer.rank.filter.enabled': boolean,
        'influencer.rank.release.enabled': boolean,
        'dashboard.showcase': boolean;
    },
    media: {
        endpoint: string;
    },
    tags: Tag[],
    gift_statuses: Dynamic,
    gift_types: Dynamic,
    attributes: Attribute[],
    deliverables: Deliverable[],
    subscriptions: Subscription[],
};

class SettingsService {
    private _settings: ClientSettings | null = null;
    private _lastPath: string | null = null;
    public readonly baseApiUrl: string;
    public readonly workerApiUrl: string;
    public readonly alertTimeout = 600;
    public readonly credentialsStorageKey = '@gifted_creds';
    public readonly imageUploadAspectRatio = 9 / 16;

    constructor() {
        let apiUrl = process.env.REACT_APP_API_ENDPOINT as string;
        if (!apiUrl) {apiUrl = ''}
        if (!apiUrl.endsWith('/')) {
            apiUrl = apiUrl + '/';
        }
        this.baseApiUrl = apiUrl;

        let workerUrl = process.env.REACT_APP_WORKER_ENDPOINT as string;
        if (workerUrl && !workerUrl.endsWith('/')) {
            workerUrl = workerUrl + '/';
        }
        this.workerApiUrl = workerUrl || apiUrl;
    }

    /**
     * Fetch and cache
     * @param brandId
     */
    public async loadSettings(brandId?: string): Promise<ClientSettings> {
        const path = brandId ? `producer/brands/${brandId}/config` :  'producer/config';
        if (this._settings && this._lastPath === path) {
            return Promise.resolve(this._settings);
        }
        return axios.get(path)
            .then((response: any) => {
                this._settings = response.data;
                this._lastPath = path;
                return this._settings as ClientSettings;
            });
    }
}

const settingsService = new SettingsService();
export default settingsService;
