import {useEffect, useState, useMemo} from 'react';
import styled from 'styled-components';

import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import { Helpers } from 'utils';
import {
    Breadcrumbs,
    InfoCard,
    StatusLoader,
    SubscriptionModal,
    Tab,
    TabItemProps,
    Button,
    Table,
    TableColumn,
    Badge,
    InviteModal
} from 'app/components';
import { CancelModal } from 'app/components/CancelModal';
import { PrivateLayout } from 'app/layouts';
import { CheckoutForm, SubscriptionForm } from 'app/forms';
import errorReporter from 'services/errorReporter';
import { API } from 'api';
import { CardAEIcon, CardVisaIcon, CardMasterCardIcon, CardIcon } from 'app/icons';
import toast, { Toaster } from 'react-hot-toast';
import AnalyticsEvents from 'services/analyticsEvents';
import {ClientSettings} from "../../../services/settingsService";


const StyledSubscriptionForm = styled(SubscriptionForm)`
    width: 100%;
    padding: 0 12px;
`;

const StyledCheckoutForm = styled(CheckoutForm)`
    padding: 0 86px;
`

const StyledTab = styled(Tab)`
    width: 100%;
    margin: 0 auto;
`;

const Container = styled.div`
    
    margin-top: 24px;
`;

const tabItems = [
    { id: 'subscription', name: 'Subscription' },
    // { id: 'billing-info', name: 'Tax invoices' }
];

export function TeamsPage() {

    const dispatcher = useAppDispatch();


    const {
        global: {
            settings
        },
        profile: {
            billing,
            brand,
            representative,
            invoices,
            team
        }
    } = useAppSelector((state) => state);

    const [showInviteModal, setShowInviteModal] = useState(false);

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);

    useEffect(() => {
        analyticsEvents.createATeamOpened();
    }, []);

    useEffect(() => {
        brand?.uid && dispatcher(Actions.Profile.getBrandProfile(brand?.uid)).catch((error: any) => {throw error});
        representative?.uid && dispatcher(Actions.Profile.getBrandTeam(representative?.uid)).catch((error: any) => {throw error})
    }, [dispatcher, brand?.uid, representative?.uid]);

    const toggleLoading = (message: string) => {
        toast.dismiss();
        toast.loading(message, {
            duration: 3000,
        });
    }

    const toggleActionSuccess = (message: string) => {
        toast.dismiss();
        toast.success(message, {
            duration: 3000,
            style: {
                textTransform: 'capitalize',
                background: '#fff',
                color: '#262161',
            }
        });
    }

    const toggleActionError = (message: string) => {
        toast.dismiss();
        toast.error(message, {
            duration: 3000,
        });
    }

    const removeTeamMember  = (email: string) => {
        if (email && representative?.uid) {
            toggleLoading('Removing team member...')
            API.Profile.removeBrandTeamInvite(email, representative?.uid).then((response) => {
                if (response) {
                    toggleActionSuccess('Team member removed');
                    dispatcher(Actions.Profile.getBrandTeam(representative?.uid)).catch((error: any) => {throw error});
                }
            }).catch((error: any) => {
                toggleActionError('Error removing team member')
            });
        }
    }

    const resendInvite = (email: string) => {
        if (email && representative?.uid) {
            toggleLoading('Sending invite...')
            API.Profile.resendBrandTeamInvite(email, representative?.uid).then((response) => {
                if (response) {
                    toggleActionSuccess('Invite sent')
                    dispatcher(Actions.Profile.getBrandTeam(representative?.uid)).catch((error: any) => {throw error});
                    analyticsEvents.createATeamInviteSent();
                }
            }).catch((error: any) => {
                toggleActionError('Error removing resending invite')
            });
        }
    }

    const refreshTeam = () => {
        if (representative?.uid) {
            dispatcher(Actions.Profile.getBrandTeam(representative?.uid)).catch((error: any) => {throw error});
            toggleActionSuccess('Invite sent!')
        }
    }

    const revokeInvite = (email: string) => {
        if (email && representative?.uid) {
            toggleLoading('Revoking team invite...')
            API.Profile.revokeBrandTeamInvite(email, representative?.uid).then((response) => {
                if (response) {
                    toggleActionSuccess('Team invite removed');
                    dispatcher(Actions.Profile.getBrandTeam(representative?.uid)).catch((error: any) => {throw error});
                }
            }).catch((error: any) => {
                toggleActionError('Error revoking team invite')
            });
        }
    }

    return (
        <PrivateLayout>
            <Container data-testid={'brandprofile-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'brandProfile', name: 'Manage Team' }
                    ]}
                />

                <Toaster />

                <StatusContainer>
                    <StatusContainerInner>
                        <h2 style={{fontWeight: 600, fontSize: '16px', transform: 'translateX(-9px)'}}>Manage your team:</h2>
                    </StatusContainerInner>
                </StatusContainer>
                
                <BillingTopContainer>

                    <BillingBox>
                        <BillingBoxHead>
                            <div>
                                <h2>Team Members</h2>
                            </div>
                            <div>
                                <button onClick={() => setShowInviteModal(true)} className='primary'>Invite team member</button>
                            </div>
                        </BillingBoxHead>

                        <BoxBody>
                            <TeamRow>
                                <div><strong>Email</strong></div>
                                <div><strong>Status</strong></div>
                                <div><strong>Actions</strong></div>
                            </TeamRow>

                            <TeamRow className='real-row'>
                                <div>{representative?.auth.email}</div>
                                <div className='status'>ACTIVE</div>
                                <div></div>
                            </TeamRow>

                            {team.map((teamMember) => (
                                <TeamRow className='real-row'>
                                    <div>{teamMember.email}</div>
                                    <div className={`status ${teamMember.invitation_status.toLowerCase() === 'pending' ? 'pending' : ''}`}>{teamMember.invitation_status === 'ACCEPTED' ? 'ACTIVE' : teamMember.invitation_status}</div>
                                    {teamMember.invitation_status.toLowerCase() === 'pending' ? (
                                        <>
                                            <button onClick={() =>  resendInvite(teamMember.email)} className='secondary'>Resend</button>
                                            <button onClick={() =>  revokeInvite(teamMember.email)} className='danger'>Revoke</button>
                                        </>

                                    ) : (
                                        <button onClick={() =>  removeTeamMember(teamMember.email)} className='danger'>Remove</button>
                                    )}
                                </TeamRow>
                            ))}

                        </BoxBody>

                    </BillingBox>


                </BillingTopContainer>
                


            </Container>


            {showInviteModal && (
                <InviteModal refresh={() => refreshTeam()} show={showInviteModal} representativeId={representative?.uid} onClose={() => setShowInviteModal(false)} />
            )}
        </PrivateLayout>
    );
}

const TeamRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: white;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: solid 1px #ECECEC;
    padding: 12px;
    transition: all 100ms ease;
    border-radius: 6px;

    div {
        // min-width: 25%;
        min-width: 42.5%;
        margin-right: 12px;
        text-align: left;

        &:last-child {
            margin-right: 0px;
        }

    }

    .status {
        color: var(--blue);
        font-weight: 600;
        text-transform: uppercase;

        &.pending {
            color: var(--blue);
            font-weight: 600;
        }
    }


    button {
        font-size: 12px;
        margin-right: 6px;
        padding: 4px 8px;
        border-radius: 6px;
        border: none;
        background: white;
        cursor: pointer;
        font-weight: 600;
        transition: all 300ms ease;
        width: 110px;

        &.primary {
            background: var(--blue);
            color: white;

            &:hover {
                background-color: rgba(38, 33, 97, 0.35);
                color: var(--blue);            
            }
        }

        &.secondary {
            border: solid 1.5px var(--blue);
            color: var(--blue);

            &:hover {
                background-color: rgba(84, 90, 119, 0.15);
                color: var(--grey-7);
            }
        }

        &.danger {
            border: solid 1.5px #FF778C;
            color: #FF778C;

            &:hover {
                background-color: rgba(255, 100, 124, 0.15);
                color: var(--error-active);
            }
        }
    }

    &.real-row {
        &:hover {
            background: #eef;
        }
    
    }

`

const StatusBadge = styled(Badge) <{
    $status?: SubscriptionStatus;
}>`
    align-items: center;
    align-self: center;
    font-size: 11px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    color: var(--grey-9);
    margin-left: 16px;
    margin-right: 16px;
    cursor: default;

    ${(props) => (props.$status === 'active' || props.$status === 'trialing') && `
        background-color: var(--success-muted);
        color: var(--success-active);
    `}
    
    ${(props) => (props.$status === 'canceled') && `
        background-color: var(--warning-muted);
        color: var(--warning-active);
    `}
    
    ${(props) => (props.$status === 'unpaid' || props.$status === 'past_due' || props.$status === 'incomplete' || props.$status === 'incomplete_expired') && `
        background-color: var(--error-muted);
        color: var(--error-active);
    `}

    &.canceled {
        background-color: var(--warning-muted);
        color: var(--warning-active);
    }

`;

const StatusContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const StatusContainerInner = styled.div`
    width: 87%;
    display: flex;
    align-items: center;
    height: 80px;
`

const InvoicesContainerOuter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
`

const InvoicesContainerInner = styled.div`
    width: 1111px;
`

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: rgba(38,33,97,0.5);
    backdrop-filter: blur(8px);
`
const Modal = styled.div`
    width: 537px;
    background-color: var(--background-alt);
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
`
const ModalContent = styled.div`
    font-weight: bold;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 8px 0;
    letter-spacing: -0.75px;
    color: var(--grey-9);
    h1 {
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: -1.25px;
        color: #262161;
        font-weight: 400;
    }
`
const ModalDesc = styled.div`
    margin-top: 24px;
    p {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.25px;
        color: #9299BE;
        font-weight: 400;
    }
`
const ModalActions = styled.div`
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
`
const ModalButton = styled(Button)`
    width: 47%;
`

const BillingTopContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
`

const BillingBox = styled.div`
    background: white;
    width: 86%;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    border-radius:6px;
    box-shadow: var(--shadow-1);
`

const BillingBoxHead = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: solid 2px #ECECEC;
    padding: 8px 16px;
    align-items: center;

    h2 {
        font-size: 16px;
        font-weight: 500;
    }

    button {
        font-size: 12px;
        margin-right: 6px;
        padding: 4px 8px;
        border-radius: 6px;
        border: none;
        background: white;
        cursor: pointer;
        font-weight: 600;
        transition: all 300ms ease;

        &.primary {
            background: var(--blue);
            color: white;

            &:hover {
                background-color: rgba(38, 33, 97, 0.35);
                color: var(--blue);            
            }
        }

        &.secondary {
            border: solid 1.5px var(--blue);
            color: var(--blue);

            &:hover {
                background-color: rgba(84, 90, 119, 0.15);
                color: var(--grey-7);
            }
        }

        &.danger {
            border: solid 1.5px #FF778C;
            color: #FF778C;

            &:hover {
                background-color: rgba(255, 100, 124, 0.15);
                color: var(--error-active);
            }
        }
    }
`

const BoxBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 16px;
`

const Item = styled.div`
    display: flex;
    flex-direction: column;

    p {
        color: #696969;
        margin: 0px;
        padding: 0px;
        font-size: 12px;
    }

    h3 {
        margin: 0px;
        padding: 0px;
        font-size: 16px;
        font-weight: 600;
        color: var(--blue);
    }
`

const BoxFooter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 32px;
    padding-right: 16px;
    padding-left: 16px; 

    div {
        font-size: 12px;
    }
`

const PaymentInfo = styled.div`
    border: solid 2px #ECECEC;
    border-radius: 6px;
    display: flex;
    position: relative;
    width: 100%;
    padding: 12px 8px;
`

const CardInfo = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        margin: 0px;
        padding: 0px;
        font-weight: 600;
        font-size: 16px;

    }

    h4 {
        margin: 0px;
        padding: 0px;
        font-weight: 500;
        color: #696969;
    }

    p {
        margin: 0px;
        padding: 0px;
        color: #696969;
    }
`

const ChangeBtn = styled.button`
    position: absolute;
    right: 8px;
    font-size: 12px;
    margin-right: 6px;
    padding: 4px 8px;
    border-radius: 6px;
    border: none;
    background: white;
    cursor: pointer;
    font-weight: 600;
    transition: all 300ms ease;
    border: solid 1.5px var(--blue);
    color: var(--blue);

    &:hover {
        background-color: rgba(84, 90, 119, 0.15);
        color: var(--grey-7);
    }
`
const StyledTableColumn = styled(TableColumn)`
    text-decoration: underline;
    text-underline-offset: 2px;
    color: var(--info-active);
    transition: color 300ms ease;
    cursor: pointer;

    &:hover {
        color: var(--aqua);
    }
`;